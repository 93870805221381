import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/f77546737d424a1f91da1727b98a157a1e4c14908716cd813cb4054e840e8e52/invoices-nav.module';

import SharedProviders_1_0 from './extensions/merchone/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule],
    providers: [...SharedProviders_1_0],
})
export class SharedExtensionsModule {}
